$(function() {
    $('html').removeClass('no-js');

    $(document).on('mouseenter', 'div.scroll-top', function () {
        $('.scroll-top').removeClass('mouseleave-animation');
        $('.scroll-top').addClass('mouseenter-animation');
    });

    $(document).on('mouseleave', 'div.scroll-top', function () {
        $('.scroll-top').removeClass('mouseenter-animation');
        $('.scroll-top').addClass('mouseleave-animation');
    });
});

$(window).on('load', function() {
    if($('[data-animate]').length > 0) {
        animateContent();
        $(window).on('scroll resize', function() {
            delayTime = 0;
            animateContent();
        });
    }
});


function animateContent() {
    delayTime = 0;
    $('[data-animate]:not(.animated)').each(function () {
        let $elem = $(this);
        if(JSH.isBottomScrolledIntoView($(this), true)) {
            delayTime = delayTime + 100;
            if(delayTime > 600) {
                delayTime = 0;
            }
            setTimeout(function(){
                $elem.addClass('animated');
            }, delayTime);
        }else {
            delayTime = 0;
        }
    });
}

$(window).on('load', function() {
    if($('.recommendation').length > 0){
        $.getJSON('/fileadmin/services/getTrustYouScore.php', function (data) {
            //console.log('Recommendations call succeeded');
            //console.log(data);
            if(data.response && data.response.score){
                // when score is less than or equal 91, insert badge 91%; otherwise insert badge with correct % (also there is no badge with 100%, therfore insert badge with 99%)
                if (data.response.score == 100) {
                    var imgurl = '/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/TrustyouBadges/trustyou_99.svg';
                } else if (data.response.score >= 91) {
                    var imgurl = '/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/TrustyouBadges/trustyou_' + data.response.score + '.svg';
                } else {
                    var imgurl = '/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/TrustyouBadges/trustyou_91.svg';
                }

                //console.log(data.response.score);
                $('.recommendation a').append('<img src="' + imgurl + '" class="figure-img lazyloaded" alt>');
            }else {
                $('.recommendation').addClass('d-none');
            }
        }).fail(function (){
            console.log('Error - Recommendations call failed');
        });
    }
});
