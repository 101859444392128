'use strict';
/**
 * JSHelpers or JSH
 * Intended to help with various JS stuff
 *
 */
(function (self, $, undefined) {

    /**
     * This method will return viewport().width or .height
     **/
    self.viewport = function () {
        var viewport = {
            width: 0,
            height: 0
        };
        // the more standards compliant browsers (mozilla/netscape/opera/IE7)
        //use window.innerWidth and window.innerHeight
        if (typeof window.innerWidth != 'undefined') {
            viewport.width = window.innerWidth,
                viewport.height = window.innerHeight
        }
        else if (typeof document.documentElement != 'undefined'
            && typeof document.documentElement.clientWidth !=
            'undefined' && document.documentElement.clientWidth != 0) {
                viewport.width = document.documentElement.clientWidth,
                viewport.height = document.documentElement.clientHeight
        }
        else {
                viewport.width = document.getElementsByTagName('body')[0].clientWidth,
                viewport.height = document.getElementsByTagName('body')[0].clientHeight
        }
        return viewport;
    },
    /**
     * This method is controlled window resize call
     * It will call your function right away ( nest it in ready/load if needed ), and after window resize
     * Default response timeout is 100ms, can be changed via 2nd arg
     */
    self.responsiveEvent = function (callback, timeout, param) {

        // call it right away
        callback(param);

        var doit;
        $(window).resize(function () {
            clearTimeout(doit);
            doit = setTimeout(callback.bind(null, param), timeout !== undefined ? timeout : 100);
        });
    },
    /**
     * This method will return name and version of the browser
     */
    self.getBrowserInfo = function () {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\bOPR\/(\d+)/)
            if (tem != null) {
                return 'Opera ' + tem[1];
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }
        return {
            name: M[0],
            version: M[1]
        };
    },

    /**
     * This method will append various info about browsers into the body class
     */
    self.identifyEnvironment = function () {
        var $tag = $('html');

        // OS
        if (self.isMobile.any()) $tag.addClass('mobile')
        else $tag.addClass('desktop');
        if (self.isMobile.Android()) $tag.addClass('android');
        if (self.isMobile.BlackBerry()) $tag.addClass('blackbery');
        if (self.isMobile.iOS()) $tag.addClass('ios');
        if (self.isMobile.Opera()) $tag.addClass('opera-os');
        if (self.isMobile.Windows()) $tag.addClass('windows');

        // BROWSER
        var browser = self.getBrowserInfo();
        if (browser.name === undefined) browser = self.isIe();
        if (browser && browser.name !== undefined) $tag.addClass(browser.name.toLowerCase());
        if (browser && browser.name !== undefined) $tag.addClass(browser.name.toLowerCase() + '-' + browser.version);
        if (browser && browser.name !== undefined) $tag.addClass('version-' + browser.version);

    },
    /**
     * Detect IE version
     */
    self.isIe = function () {

        var version = (/MSIE 9/i.test(navigator.userAgent)) ? 9 : false;
        if (!version) version = (/MSIE 10/i.test(navigator.userAgent)) ? 10 : false;
        if (!version) version = (/rv:11.0/i.test(navigator.userAgent)) ? 11 : false;

        if (version) return {
            name: 'msie',
            version: version
        }

        return false;
    },
    /**
     * Object that contains methods that will check which OS is running on the device
     */
    self.isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (self.isMobile.Android() || self.isMobile.BlackBerry() || self.isMobile.iOS() || self.isMobile.Opera() || self.isMobile.Windows() == true) ? true : false;
        }
    },
    /**
     * This method will return value by URL arg name
     */
    self.getParam = function (name) {
        name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(window.location.href);
        if (results == null) return undefined;
        else return decodeURIComponent(results[1]);
    },
    /**
     * This will return hash value, simple as that
     */
    self.getHash = function () {
        if (window.location.hash) {
            return window.location.hash.substring(1);
        }

        return undefined;
    },
    /**
     * This method will check if searched string exists in the url
     **/
    self.urlContains = function (what) {
        if (window.location.href.indexOf(what) > -1) return true;
        else return false;
    },
    /**
     * return document language
     */
    self.getLanguage = function () {
        return $('html').attr('lang');
    },
    /**
     * return scrolling offset y
     */
    self.getCurrentOffset = function () {
        return window.pageYOffset;
    },
    /**
     * This method will fix height of the boxes, just add data-fixheight="true" attribute to them
     * Example: <div data-fixheight="true" data-group="projects"></div> => just activate it on Window.load without selector ;)
     */
    self.equalHeightColumns = function (selector) {
        self.responsiveEvent(function () {

            var blocksByGroups = [];
            var groupHeights = [];
            var $fixHeightBlocks = selector !== undefined ? $(selector) : $('body *[data-fixheight="true"]');

            // quick reset of the old values
            $fixHeightBlocks.css('min-height', '0px');
            //$fixHeightBlocks.css('height', 'auto');

            // if deactivated
            var deactivateOn = $fixHeightBlocks.attr('data-deactivate');
            if (deactivateOn >= self.viewport().width) return;

            // put blocks to groups
            $fixHeightBlocks.each(function () {
                var $block = $(this);
                var group = $block.attr('data-group');
                if (group === undefined) group = 'default';
                if (group !== undefined) {
                    // check if we already have that group
                    if (typeof blocksByGroups[group] != 'object') {
                        // create the group
                        blocksByGroups[group] = [];
                    }
                    // just add the block into it
                    blocksByGroups[group].push($block);
                }
            });

            // go group by group
            for (var group in blocksByGroups) {
                // and fix height
                var biggestHeight = 0;
                $.each(blocksByGroups[group], function () {
                    var $blocks = $(this);
                    $blocks.each(function () {
                        if ($(this).outerHeight() > biggestHeight) {
                            biggestHeight = $(this).outerHeight();
                        }
                    });
                });

                // ceil and +1 because its sometimes buggy in IE
                biggestHeight = Math.ceil(biggestHeight + 1);
                // save that height ( debugging purpose )
                groupHeights[group] = biggestHeight;

                // set height to all group's items
                $(blocksByGroups[group]).each(function () {
                    $(this).css('min-height', biggestHeight);
                });
            }
        });
    },
    /**
     * true/false if element is visible
     */
    self.isScrolledIntoView = function (elem, rightAway) {
        // dummy check
        if ($(elem).length > 0) {
            var $elem = $(elem);
            var $window = $(window);

            var docViewTop = $window.scrollTop();
            var docViewBottom = docViewTop + $window.height();

            var elemTop = $elem.offset().top;
            var elemBottom = (rightAway) ? elemTop : elemTop + $elem.height();
            return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
        }
    },
    /**
     * true/false if element is visible
     */
    self.isBottomScrolledIntoView = function (elem, rightAway) {
        // dummy check
        if ($(elem).length > 0) {
            var $elem = $(elem);
            var $window = $(window);

            var docViewTop = $window.scrollTop();
            var docViewBottom = docViewTop + $window.height();

            var elemTop = $elem.offset().top;
            var elemBottom = (rightAway) ? elemTop : elemTop + $elem.height();
            return (elemBottom <= docViewBottom);
        }
    },
    /**
     * return scrolling offset y
     */
    self.getCurrentOffset = function () {
        return window.pageYOffset;
    },
    /**
     * get page ID from body class (page-{id})
     */
    self.getCurrentPageId = function () {
        var cl = $("body").attr("class").match(/page[\w-]*\b/)[0];
        var pageId = cl.split("-")[1];
        if (parseInt(pageId) > 0) return parseInt(pageId);
        else return -1;
    },

    self.initDatepickerPair = function ($start, $end, container) {
        if ($(container).length < 0) {
            container = 'body';
        }

        var language = self.getLanguage();

        /*$start.attr('readonly', true);
        $end.attr('readonly', true);*/

        var nowTemp = new Date();

        var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
        var checkin = $start.datepicker({
            language: language,
            container: container,
            weekStart: 1,
            autoclose: true,
            disableTouchKeyboard: true,
            format: 'dd.mm.yyyy',
            startDate: now
        }).on('changeDate', function (ev) {

            //var newDate = new Date(ev.date);
            var plus = new Date(ev.date);
            plus.setDate(plus.getDate() + 1);

            if (checkout.getDate() && ev.date >= checkout.getDate()) {
                checkout.setDate(plus);
            }

            checkout.setStartDate(plus);
            checkin.hide();
            if ($start.prop('required')) {
                //$start.valid();
                $start.parsley().validate()
            }
            //$start.addClass('filled');

        }).data('datepicker');

        var checkout = $end.datepicker({
            language: language,
            container: container,
            weekStart: 1,
            autoclose: true,
            disableTouchKeyboard: true,
            format: 'dd.mm.yyyy',
            startDate: now
        }).on('changeDate', function (ev) {
            checkout.hide();
            if ($end.prop('required')) {
                //$end.valid();
                $start.parsley().validate()
            }
            //$end.addClass('filled');
        }).data('datepicker');


        /* $start.datepicker('setDate', nowTemp);
         var tomorrowTemp = new Date();
         tomorrowTemp.setDate(tomorrowTemp.getDate()+1);
         $end.datepicker('setDate', tomorrowTemp);*/

        /* if($start.val()){
             var plus = checkin.getDate();
             plus.setDate(plus.getDate() + 1);
             checkout.setStartDate(plus);
         }

         $start.on('click', function(e) {
             e.stopPropagation();
             e.preventDefault();
             checkout.hide();
         });

         $end.on('click', function(e) {
             e.stopPropagation();
             e.preventDefault();
             checkin.hide();
         });*/
    },
    self.initDatepickerSingle = function ($element, container) {
        if ($(container).length < 0) {
            container = 'body';
        }
        var language = self.getLanguage();
        /*var $readonlyElement = (typeof $element == 'input') : $element ? $element.find('input[type="text"]');
        $readonlyElement.attr('readonly', true);*/
        var nowTemp = new Date();

        var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);

        var picker = $element.datepicker({
            language: language,
            container: container,
            weekStart: 1,
            autoclose: true,
            disableTouchKeyboard: true,
            format: 'dd.mm.yyyy',
            startDate: now
        }).on('changeDate', function (ev) {
            if ($element.prop('required')) {
                //$element.valid();
                $element.parsley().validate();
            }
        });

        /*if($element.val() == ''){
            var nowTemp = new Date();
            //Set date 20 years ago...
            var yearsAgo = new Date(nowTemp.getFullYear() - 20, nowTemp.getMonth(), nowTemp.getDate());
            $element.datepicker('setDate', yearsAgo);
            $element.val('');

        }*/

    },
    self.initDatepickerBirthdate = function ($element, container) {
        if ($(container).length < 0) {
            container = 'body';
        }
        var language = self.getLanguage();
        $element.attr('readonly', true);

        var picker = $element.datepicker({
            weekStart: 1,
            startView: 1,
            autoclose: true,
            orientation: 'auto',
            language: language,
            //format: 'dd.mm.yyyy',
        }).on('changeDate', function (ev) {
            $element.addClass('filled');
        });

        /*if($element.val() == ''){
            var nowTemp = new Date();
            //Set date 20 years ago...
            var yearsAgo = new Date(nowTemp.getFullYear() - 20, nowTemp.getMonth(), nowTemp.getDate());
            $element.datepicker('setDate', yearsAgo);
            $element.val('');

        }*/

    },
    self.getMultipleScripts = function (arr) {
        var _arr = $.map(arr, function (scr) {
            return $.getScript(scr);
        });
        _arr.push($.Deferred(function (deferred) {
            $(deferred.resolve);
        }));
        return $.when.apply($, _arr);
    }

})(window.JsHelpers = window.JsHelpers || {}, jQuery);

// create alias for core
window.JSH = window.JsHelpers;